import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const PhotoManager = ({ sections, images }) => {
  const [sectionImages, setSectionImages] = useState(images || {});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingSection, setUploadingSection] = useState(null);
  const [previewImages, setPreviewImages] = useState({}); // For storing preview URLs
  const [unsavedChanges, setUnsavedChanges] = useState({}); // Track unsaved visibility changes

  const fileInputRefs = useRef({}); // Refs for file inputs per section

  useEffect(() => {
    if (images) {
      setSectionImages(images);
    }
  }, [images, sections]);

  const fetchImages = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/menu-images`,
      {
        headers: {
          "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const rawData = await response.json();

    const updatedImages = {};
    rawData
      .filter((item) => item.Name !== "banner") // Exclude the banner image
      .forEach((item) => {
        updatedImages[item.Name] = {
          id: item.ID, // Include the id
          ImageURL: item.ImageURL,
          visibility: item.Visibility, // Include the ImageURL
        };
      });

    setSectionImages(updatedImages); // Set the remaining images to the state
  };

  // Handle image upload
  const handleImageUpload = async (file, sectionId, fileId, visibility) => {
    const formData = new FormData();
    console.log(file );
    console.log(sectionId );
    console.log(fileId );
    console.log(visibility );

    formData.append("visibility", visibility);
    formData.append("photo", file);

    setIsUploading(true);
    setUploadingSection(sectionId);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/menu-images/${fileId}`,
        {
          method: "PUT",
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`,
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        fetchImages();
        setUnsavedChanges((prev) => ({ ...prev, [sectionId]: false })); // Reset unsaved changes

      } else {
        console.error("Failed to upload image for section:", sectionId);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
      setUploadingSection(null);
      setPreviewImages((prev) => ({ ...prev, [sectionId]: null })); // Clear preview
    }
  };

  // Handle file selection
  const handleFileSelect = (event, sectionId) => {
    const file = event.target.files[0];
    if (file) {
      // Preview image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prev) => ({
          ...prev,
          [sectionId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);

      // Store selected file
      if (!fileInputRefs.current[sectionId]) {
        fileInputRefs.current[sectionId] = {};
      }
      fileInputRefs.current[sectionId].file = file;
    }
  };
  /* 
  // Confirm the upload
  const handleConfirm = (sectionId, fileId, visibility) => {
    const file = fileInputRefs.current[sectionId]?.file;
    if (file) {
      handleImageUpload(file, sectionId, fileId, visibility);
    }
  };

  // Cancel the operation
  const handleCancel = (sectionId) => {
    setPreviewImages((prev) => ({ ...prev, [sectionId]: null }));
    if (fileInputRefs.current[sectionId]) {
      fileInputRefs.current[sectionId].file = null;
    }
  };

  // Handle visibility change
  const handleVisibilityChange = (sectionId, newVisibility) => {
    setSectionImages((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        visibility: newVisibility,
      },
    }));
  }; */
  // Confirm the upload or visibility change
  const handleConfirm = (sectionId, fileId, visibility) => {
    const file = fileInputRefs.current[sectionId]?.file;
    handleImageUpload(file, sectionId, fileId, visibility);
  };

  // Cancel the operation
  const handleCancel = (sectionId) => {
    setPreviewImages((prev) => ({ ...prev, [sectionId]: null }));
    if (fileInputRefs.current[sectionId]) {
      fileInputRefs.current[sectionId].file = null;
    }
    setUnsavedChanges((prev) => ({ ...prev, [sectionId]: false })); // Reset unsaved changes
  };

  // Handle visibility change
  const handleVisibilityChange = (sectionId, newVisibility) => {
    setSectionImages((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        visibility: newVisibility,
      },
    }));
    setUnsavedChanges((prev) => ({ ...prev, [sectionId]: true })); // Mark as unsaved
  };

  return (
    <Box flex={1} width="100%" padding={3} boxShadow={3} borderRadius={2}>
      <Typography variant="h5" color="black" mb={3}>
        Fotos do Menu
      </Typography>

      <Box display="flex" flexWrap="wrap" gap="20px" justifyContent="center">
        {sections.map((section) => (
          <Box
            key={section.id}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="10px"
            border="1px solid #000"
            borderRadius="8px"
            padding="20px"
          >
            <Typography variant="h6" color="black">
              {section.title}
            </Typography>

            {previewImages[section.id] ? (
              // Show preview image
              <>
                <img
                  src={previewImages[section.id]}
                  alt={`Preview for ${section.title}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
                <Box display="flex" gap="10px" mt="10px">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleConfirm(
                        section.id,
                        sectionImages[section.id].id,
                        sectionImages[section.id].visibility
                      )
                    }
                    disabled={isUploading}
                  >
                    Confirmar
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleCancel(section.id)}
                    disabled={isUploading}
                  >
                    Cancelar
                  </Button>
                </Box>
              </>
            ) : sectionImages[section.id] ? (
              // Show current image
              <img
                src={sectionImages[section.id].ImageURL}
                alt={section.title}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <Typography variant="body2" color="black">
                Nenhuma imagem disponível
              </Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionImages[section.id]?.visibility || false}
                  onChange={(e) =>
                    handleVisibilityChange(section.id, e.target.checked)
                  }
                />
              }
              label="Visível"
            />

            {unsavedChanges[section.id] && (
              <Box display="flex" gap="10px" mt="10px">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleConfirm(
                      section.id,
                      sectionImages[section.id].id,
                      sectionImages[section.id].visibility
                    )
                  }
                  disabled={isUploading}
                >
                  Confirmar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleCancel(section.id)}
                  disabled={isUploading}
                >
                  Cancelar
                </Button>
              </Box>
            )}

            {!previewImages[section.id] && (
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  fontWeight: 400,
                }}
                disabled={isUploading && uploadingSection === section.id}
              >
                Alterar Foto
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  onChange={(event) => handleFileSelect(event, section.id)}
                />
              </Button>
            )}

            {isUploading && uploadingSection === section.id && (
              <CircularProgress size={24} sx={{ mt: 1 }} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PhotoManager;
