import React, { useState } from "react";
import { Box, Button, IconButton, TextField, Snackbar, Alert } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";


import NotificationToggle from "../../global/ShowNotificationField";


const Form = ({ onClose, refreshNews, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [imagePreview, setImagePreview] = useState(null);



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [notificationData, setNotificationData] = useState(null);



  const handleChanges = (newFile) => {
    // Check if the file is selected
    if (newFile) {
      // Check for image type
      if (!newFile.type.startsWith("image/")) {
        setSnackbarMessage("Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setFile(null);
        setImagePreview(null);
        return;
      }

      // Check file size (20MB limit)
      const maxSizeInMB = 20; // Maximum size in MB
      if (newFile.size > maxSizeInMB * 1024 * 1024) {
        setSnackbarMessage("O tamanho do ficheiro não pode exceder 20MB.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setFile(null);
        setImagePreview(null);
        return;
      }

      // Set the file if all checks pass
      setFile(newFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImagePreview(null);
    }
  };


  const handleRemoveImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleFormSubmit = async (values, setSubmitting) => {

    if (!file) {
      setSnackbarMessage("Por favor, adicione uma imagem antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Prevent form submission
      return;
    }


    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("subtitle", values.subtitle);
    formData.append("author", values.author);
    formData.append("description", values.description);

    if (file) {
      formData.append("photo", file);
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/news`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: {
          "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
        },
/*         headers: {
          Accept: "application/json",
        }, */
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      refreshNews();
      showSnackbar("Notícia adicionada com sucesso!");

      if (notificationData && notificationData.title && notificationData.body) {
        await sendNotification(notificationData);
      }

      onClose();
      const responseData = response.body;
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao Adicionar Notícia. Por favor tente outra vez.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setSubmitting(false);
  };





  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/notifications/send`,
        {
          method: "POST",
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
          credentials: "include",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbarMessage(`Error sending notification: ${errorData.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending notification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };



  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Título"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Subtítulo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subtitle}
                name="subtitle"
                error={!!touched.subtitle && !!errors.subtitle}
                helperText={touched.subtitle && errors.subtitle}

              />

              {imagePreview && (
                <Box display="flex" justifyContent="center" gap="10px" mb="10px" position="relative">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "200px",
                      height: "auto",
                      borderRadius: "8px",

                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={handleRemoveImage}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "#fff",

                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon />
                    </InputAdornment>
                  ),
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                placeholder="Escolha uma imagem"
                value={file}
                onChange={handleChanges}
                accept="image/*" // Allow only image files
              />



              <TextField
                fullWidth
                variant="filled"
                multiline
                minRows={6}
                maxRows={300}
                type="text"
                label="Corpo da notícia"
                placeholder="Insira a notícia"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"

                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}

              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Autor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.author}
                name="author"

                error={!!touched.author && !!errors.author}
                helperText={touched.author && errors.author}

              />
            </Box>

            <NotificationToggle
              defaultTitle="Nova Notícia"
              defaultBody="Nova notícia! Fica já a par das novidades"
              screen="/news"
              onSend={(data) => setNotificationData(data)}
            />





            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem",
                  color: colors.customTheme.textColor,
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                  },
                }} variant="contained"
                disabled={isSubmitting} // Disable the button when submitting
              >
                {isSubmitting ? "A guardar notícia..." : "Criar Notícia"}{" "}
                {/* Change button text when submitting */}
              </Button>
            </Box>
          </form>
        )}
      </Formik>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
  subtitle: yup.string().required("Campo obrigatório"),
  author: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  title: "",
  subtitle: "",
  author: "",
  description: "",
  photo: null,
};

export default Form;
