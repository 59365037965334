import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Link,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Alert,
  Snackbar
} from "@mui/material";
import Header from "../../../components/Header";
import TableWithPagination from "../../global/Table";
import { ValidUserContext } from "../../login/authCheck";
import SnackbarComponent from "../../global/Snackbar";
import Overlay from "../../global/Overlay"; // Assuming you have an Overlay component
import AddSubjectForm from "./addSubject";

const CourseDetail = () => {
  const { state } = useLocation(); // Course data passed from the previous page
  const { userRoles } = useContext(ValidUserContext);

  const navigate = useNavigate();
  const course = state?.course; // Extract the course data
  const [subjects, setSubjects] = useState([]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isCreatingYear, setIsCreatingYear] = useState(false);
 
  const [overlayOpen, setOverlayOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 750px)");



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");



  // State for image upload
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  // Ref for file input
  const fileInputRef = useRef(null);


  // Define columns for the years
  const columns = [
    { label: "ID", field: "id", hideAlways: true },
    { label: "Nome", field: "name" },
    { label: "Ano", field: "year" },
    { label: "Semestre", field: "semester" },

  ];

  const fetchSubjects = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/notes-bank/courses/${course.id}/subjects`,
      {
        headers: {
          "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
        },
      }
    );
    const rawData = await response.json();
    const data = rawData.map((course) => ({
      ...course,
      id: course.ID,
    }));
    console.log(data);
    setSubjects(data);
  };

  useEffect(() => {
    if (course) {
      fetchSubjects();
    }
  }, [course]);

  if (!course) {
    return <div>No course found.</div>;
  }

  // Handle navigation to the content of a specific year
  const handleYearClick = (subject) => {
    navigate(`/bancoApontamentos/${course.id}/${subject.id}`, {
      state: { subject, course },
    });
  };

  // Handle creating a new year
  const handleCreateYear = () => {
    setIsCreatingYear(true);
    setOverlayOpen(true);
  };

  // Close overlay
  const closeOverlay = () => {
    setOverlayOpen(false);
    setIsCreatingYear(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Method to handle course deletion
  const handleDeleteCourse = async () => {
    if (subjects.length !== 0) {
      setSnackbarMessage("Não pode eliminar este curso porque tem cadeiras associadas");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
  
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/notes-bank/courses/${course.id}`;
      const response = await fetch(url, 
        {
           method: "DELETE",
           headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
          credentials: "include",
         }
        );
  
      if (!response.ok) {
        throw new Error("Failed to delete course");
      }
  
      // Exibe o Snackbar e espera alguns segundos antes de navegar
      setSnackbarMessage("Curso eliminado com sucesso!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
  
      setTimeout(() => {
        setOpenDeleteDialog(false); // Fecha o diálogo de exclusão
        navigate("/bancoApontamentos"); // Navega de volta para a lista de cursos
      }, 2000); // Atraso de 2 segundos
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Ocorreu um erro ao eliminar o curso.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  

  // Função para enviar o formulário
  const handleFormSubmit = async (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("name", values.name); 
    formData.append("year", values.yearName); 
    formData.append("semester", values.semester); 

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/notes-bank/courses/${course.id}/subjects`; // Replace with your API

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
        },
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Erro na rede ao tentar adicionar o ano");
      }
      const responseData = await response.text();
      fetchSubjects(); // Atualiza a lista de anos após adicionar
      showSnackbar("Unidade Curricular adicionada com sucesso!"); // Mostra uma mensagem de sucesso
      closeOverlay(); // Fecha o formulário após a submissão
    } catch (error) {
      console.error("Erro:", error);
    }

    setSubmitting(false); // Reseta o estado de envio
  };




  // Handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreviewUrl(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmImage = () => {
    if (selectedImage) {
      // Logic to upload the image can be added here
      console.log("Image confirmed:", selectedImage);
      resetImageSelection(); // Reset after confirmation
    }
  };

  const handleCancelImage = () => {
    resetImageSelection(); // Reset on cancel
  };

  const resetImageSelection = () => {
    setSelectedImage(null);
    setImagePreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };







  // Check if the user can add years
  const canAddYears = userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%" p={3}>
      {/* Page Header */}

      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate("/bancoApontamentos")}
          style={{ cursor: "pointer" }} // Add pointer cursor to indicate it's clickable
        >
          Banco de Apontamentos
        </Link>
        <Link
          underline="hover"
          color="inherit"
          onClick={() =>
            navigate(`/bancoApontamentos/${course.id}`, { state: { course } })
          }
          style={{ cursor: "pointer" }}
        >
          {course.Name}
        </Link>
      </Breadcrumbs>

      
      <Box
       width="100%"
       display="flex"
       justifyContent="space-between"
       flexDirection={isMobile ? "column" : "row"} // Column for mobile, row for larger screens
       alignItems="center"
       mt={2}
       mb={2}
      >

        <Box></Box>
              <Header title={course.Name} subtitle="Cadeiras disponíveis para este curso" showPhotoImporter={false} />
              <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={2}
        mb={2}
      >
        {!imagePreviewUrl && (
          <label htmlFor="photo-importer">
            <Button
              variant="contained"
              component="span"
              sx={{
                backgroundColor: "primary.main",
                color: "#fff",
                fontWeight: 400,
                mb: 1,
              }}
            >
              Alterar Foto Curso
            </Button>
          </label>
        )}

        {!imagePreviewUrl && course.ImageURL && (
          <img
            src={course.ImageURL}
            alt={`${course.Name} image`}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "contain",
              borderRadius: "8px",
              boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.3)",
            }}
          />
        )}

        {imagePreviewUrl && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <img
              src={imagePreviewUrl}
              alt="Preview"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
            <Box mt={2} display="flex" gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmImage}
              >
                Confirmar
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancelImage}>
                Cancelar
              </Button>
            </Box>
          </Box>
        )}

        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={fileInputRef}
          id="photo-importer"
        />
      </Box>





      </Box>
      

     {/* Course Image */}
   
   






      {/* Table of Years */}
      <TableWithPagination
        columns={columns}
        rows={subjects} // Use the years from the course object
        onRowClick={handleYearClick} // Navigate to year details on row click
        buttonTitle="Cadeira"
        canAddElements={canAddYears}
        onButtonClick={handleCreateYear} // Function to create a new year
        onDeleteClick={() => setOpenDeleteDialog(true)}
        deleteButtonTitle="Curso"
        canDeleteElements={true}
      />

      {/* Overlay for creating/editing a year */}
      <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingYear ? "Criar Unidade Curricular" : "Editar Unidade Curricular"}
      >
        <AddSubjectForm
          onClose={closeOverlay}
          onAddSubject={handleFormSubmit}
          refreshYears={fetchSubjects}
          showSnackbar={showSnackbar}
        />
      </Overlay>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Eliminar Curso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem a certeza que deseja eliminar este curso? Esta ação eliminará
            também todos os conteúdos associados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteCourse} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CourseDetail;
