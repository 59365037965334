import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import NotificationToggle from "../../global/ShowNotificationField";

const AddSubjectForm = ({ onClose, onAddSubject, refreshYears, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [notificationData, setNotificationData] = useState(null);

  // Handle the form submit logic
  const handleFormSubmit = async (values, setSubmitting) => {
    try {
      // Call the onAddSubject function (this will be passed down from parent)
      await onAddSubject(values);

      // If notification data is available, send it
      if (notificationData && notificationData.title && notificationData.body) {
        await sendNotification(notificationData);
      }

      // Refresh the list of years or subjects
      refreshYears();

      // Show success message
      showSnackbar("Cadeira adicionada com sucesso!");

      // Close the form after successful submission
      onClose();
    } catch (error) {
      console.error("Error adding subject:", error);
      showSnackbar("Erro ao adicionar a cadeira. Tente novamente.");
    }

    // Reset the submitting state
    setSubmitting(false);
  };

  // Function to handle sending notifications
  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/notifications/send`,
        {
          method: "POST",
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        showSnackbar(`Erro ao enviar a notificação: ${errorData.message}`);
      }
    } catch (error) {
      showSnackbar("Erro ao enviar a notificação.");
    }
  };

  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={subjectSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" gap="20px" flexDirection="column">
              {/* Subject Name Input */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome da Cadeira"
                placeholder="Insira o nome da cadeira"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />

              {/* Year Input */}
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Ano"
                placeholder="Insira o ano"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.yearName}
                name="yearName"
                error={!!touched.yearName && !!errors.yearName}
                helperText={touched.yearName && errors.yearName}
              />

              {/* Semester Input */}
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Semestre"
                placeholder="Insira o semestre"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.semester}
                name="semester"
                error={!!touched.semester && !!errors.semester}
                helperText={touched.semester && errors.semester}
              />
            </Box>

            {/* Notification Toggle Component 
            
              <NotificationToggle
              defaultTitle="Nova cadeira adicionada"
              defaultBody={`Descobre tudo o que precisas para o teu próximo 20`}
              onSend={(data) => setNotificationData(data)}
            />
            
            */}
          

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem",
                  fontWeight: 600,
                  backgroundColor: colors.customTheme.primary,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: colors.customTheme.primary,
                    color: "#fff",
                    opacity: 0.7,
                  },
                }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar cadeira..." : "Criar Cadeira"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Validation schema for the form
const subjectSchema = yup.object().shape({
  name: yup.string().required("Nome da Unidade Curricular é obrigatório"),
  yearName: yup.string().required("O ano é obrigatório. Apenas são aceites valores numéricos"),
  semester: yup.string().required("O semestre é obrigatório. Apenas são aceites valores numéricos"),
});

// Initial form values
const initialValues = {
  name: "",
  yearName: "",
  semester: "",
};

export default AddSubjectForm;
