import { useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Loading from "./scenes/global/LoadingPage";
import Users from "./scenes/users/usersList";
import News from "./scenes/news/newsList";
import Services from "./scenes/services/servicesList";
import Contacts from "./scenes/contacts/contactsList";
import CreateUser from "./scenes/users/createUser";
import CreateContact from "./scenes/contacts/createContact";
import CreateService from "./scenes/services/createService";
import CreateAEDocument from "./scenes/ae/createAEDocument";
import CreateNews from "./scenes/news/createNews";
import Login from "./scenes/login";
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { useMode } from "./theme";
import { ValidUserContext } from "./scenes/login/authCheck";
import ContactDetails from "./scenes/contacts/contactDetails";
import ServiceDetails from "./scenes/services/serviceDetails";
import NewsDetails from "./scenes/news/newsDetails";
import AEDocuments from "./scenes/ae/aeDocumentsList";
import DocumentDetails from "./scenes/ae/documentDetails";
import ProfilePage from "./scenes/profile";
import footerImage from "./assets/3CKET.png";
import { tokens } from "./theme";
import ForgotPassword from "./scenes/login/pwdChangeRequest";
import CourseDetail from "./scenes/notes/subjects";
import CoursesList from "./scenes/notes/courses";
import SubjectContent from "./scenes/notes/documents";
import NotificationsPage from "./scenes/notifications";

function App() {
  const [theme] = useMode();
  const { isLoggedIn, isLoading, userRoles } = useContext(ValidUserContext);
  const [isSidebar, setIsSidebar] = useState(true);
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery("(max-width:750px)");

  let routes = (
    <Routes>
      <Route path="*" element={<Loading />} />
    </Routes>
  );

  if (isLoggedIn) {
    if (userRoles.includes("SU")) {
      routes = (
        <Routes>
          <Route path="/users" element={<Users />} />

          <Route path="/news" element={<News />} />
          <Route path="/createNews" element={<CreateNews />} />
          <Route path="/newsDetails/:id" element={<NewsDetails />} />

          <Route path="/services" element={<Services />} />
          <Route path="/createService" element={<CreateService />} />
          <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

          <Route path="/contacts" element={<Contacts />} />
          <Route path="/createContact" element={<CreateContact />} />
          <Route path="/contactDetails/:id" element={<ContactDetails />} />

          <Route path="/ae" element={<AEDocuments />} />
          <Route path="/createAEDocument" element={<CreateAEDocument />} />
          <Route path="/AEDocumentDetails/:id" element={<DocumentDetails />} />

          <Route path="/bancoApontamentos" element={<CoursesList />} />
          <Route
            path="/bancoApontamentos/:courseId"
            element={<CourseDetail />}
          />
          <Route
            path="/bancoApontamentos/:courseId/:yearId"
            element={<SubjectContent />}
          />
          <Route path="/notifications" element={<NotificationsPage />} />

          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/*" element={<News />} />
        </Routes>
      );
    } else if (userRoles.includes("ADMIN")) {
      routes = (
        <Routes>
          <Route path="/users" element={<Users />} />

          <Route path="/news" element={<News />} />
          <Route path="/createNews" element={<CreateNews />} />
          <Route path="/newsDetails/:id" element={<NewsDetails />} />

          <Route path="/services" element={<Services />} />
          <Route path="/createService" element={<CreateService />} />
          <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

          <Route path="/contacts" element={<Contacts />} />
          <Route path="/createContact" element={<CreateContact />} />
          <Route path="/contactDetails/:id" element={<ContactDetails />} />

          <Route path="/ae" element={<AEDocuments />} />
          <Route path="/createAEDocument" element={<CreateAEDocument />} />
          <Route path="/AEDocumentDetails/:id" element={<DocumentDetails />} />

          <Route path="/bancoApontamentos" element={<CoursesList />} />
          <Route
            path="/bancoApontamentos/:courseId"
            element={<CourseDetail />}
          />
          <Route
            path="/bancoApontamentos/:courseId/:yearId"
            element={<SubjectContent />}
          />
          <Route path="/notifications" element={<NotificationsPage />} />

          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/*" element={<News />} />
        </Routes>
      );
    } else if (userRoles.includes("NEWS")) {
      routes = (
        <Routes>
          <Route path="/users" element={<Users />} />

          <Route path="/news" element={<News />} />
          <Route path="/createNews" element={<CreateNews />} />
          <Route path="/newsDetails/:id" element={<NewsDetails />} />

          <Route path="/services" element={<Services />} />
          <Route path="/serviceDetails/:id" element={<ServiceDetails />} />

          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contactDetails/:id" element={<ContactDetails />} />

          <Route path="/ae" element={<AEDocuments />} />
          <Route path="/AEDocumentDetails/:id" element={<DocumentDetails />} />

          <Route path="/bancoApontamentos" element={<CoursesList />} />
          <Route
            path="/bancoApontamentos/:courseId"
            element={<CourseDetail />}
          />
          <Route
            path="/bancoApontamentos/:courseId/:yearId"
            element={<SubjectContent />}
          />
          <Route path="/notifications" element={<NotificationsPage />} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/*" element={<News />} />
        </Routes>
      );
    }
  } else if (!isLoading) {
    routes = (
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        className="app"
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          height: "100vh",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {isLoggedIn && <Sidebar isSidebar={isSidebar} />}

        <main
          className="content"
          style={{
            flexGrow: 1, // Main content should take up the remaining space
            overflowY: "auto", // Enable vertical scrolling for the content
            position: "relative",
            paddingTop: "5rem",
            paddingBottom: "5rem", // Ensure space for the footer
            marginBottom: "5rem",
            paddingTop: "5rem",
            paddingBottom: "5rem", // Ensure space for the footer
            marginBottom: "5rem",
          }}
        >
          {isLoggedIn && <Topbar setIsSidebar={setIsSidebar} />}
          <Box
            sx={{
              height: "100%", // Ensure content fills available height
              padding: 0, // Remove any padding
              margin: 0,
              width: "90%",
              width: "95%",
            }}
          >
            {routes}
          </Box>

         
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
