import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { MuiFileInput } from "mui-file-input";
import { tokens } from "../../../theme";
import { useTheme,Alert, Snackbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";


import NotificationToggle from "../../global/ShowNotificationField";

const Form = ({ onClose, refreshServices, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [tags, setTags] = useState([]); // Change to manage tags directly
  const [customTag, setCustomTag] = useState(""); // Store custom tag input
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [notificationData, setNotificationData] = useState(null);


  const handleChanges = (newFile) => {
    
    if (newFile) {
      // Check for image type
      if (!newFile.type.startsWith("image/")) {
          setSnackbarMessage("Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);     
          setFile(null);
          setImagePreview(null);
          return;
      }

      // Check file size (20MB limit)
      const maxSizeInMB = 20; // Maximum size in MB
      if (newFile.size > maxSizeInMB * 1024 * 1024) {
          setSnackbarMessage("O tamanho do ficheiro não pode exceder 20MB.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setFile(null);
          setImagePreview(null);
          return;
      }
    };
    
    
    setFile(newFile);
    // Cria uma URL de preview da imagem anexada
    if (newFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImagePreview(null);
    }
  };




  const handleRemoveImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleAddTag = () => {
    if (customTag && !tags.includes(customTag)) {
      setTags([...tags, customTag]); // Add the tag to the state
      setCustomTag(""); // Clear input after adding
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove)); // Remove the specified tag
  };




  const handleFormSubmit = async (values, setSubmitting) => {
   
    if (!file) {
      setSnackbarMessage("Por favor, adicione uma imagem antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Prevent form submission
      return;
    }

    if (tags.length === 0) {
      setSnackbarMessage("Por favor, adicione pelo menos uma tag antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Prevent form submission
      return;
    }
  
   
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("type", values.type);
      formData.append("schedule", values.schedule);
      formData.append("address", values.address);
      tags.forEach((tag) => {
        formData.append("tags", tag);
      });

      if (file) {
        formData.append("photo", file); // Envia a foto junto ao formulário
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/services`,
        {
          method: "POST",
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
          credentials: "include",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const responseData = await response.text();
      // console.log("Success:", responseData);
      refreshServices();
      showSnackbar("Serviço adicionado com sucesso!");

      if (notificationData && notificationData.title && notificationData.body) {
        await sendNotification(notificationData);
      }


      onClose();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao Adicionar Serviço. Por favor tente outra vez.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setSubmitting(false);
    }
  };



  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/notifications/send`,
        {
          method: "POST",
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
          credentials: "include",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbarMessage(`Error sending notification: ${errorData.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending notification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };



  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />

              {/* Image preview with remove icon */}
              {imagePreview && (
                <Box
                  display="flex"
                  justifyContent="center"
                  mb="10px"
                  position="relative"
                >
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "25vw",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={handleRemoveImage}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "#000",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon />
                    </InputAdornment>
                  ),
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                placeholder="Carregar imagem"
                value={file}
                onChange={handleChanges}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Tipo de Serviço"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Horário de Funcionamento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.schedule}
                name="schedule"
                error={!!touched.schedule && !!errors.schedule}
                helperText={touched.schedule && errors.schedule}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Morada"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
              />

              {/* Tag Input Field */}
              <Box display="flex" alignItems="center">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Adicionar Tag"
                  value={customTag}
                  onChange={(e) => setCustomTag(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddTag(); // Allow adding tag with Enter key
                      e.preventDefault();
                    }
                  }}
                />
                <Button onClick={handleAddTag} sx={{ marginLeft: "10px" }}>
                  <AddIcon />
                </Button>
              </Box>

              {/* Display Added Tags */}
              <Box mt={2}>
                {tags.map((tag) => (
                  <Box
                    key={tag}
                    display="inline-flex"
                    alignItems="center"
                    borderRadius="4px"
                    padding="5px"
                    marginRight="10px"
                    marginBottom="10px"
                  >
                    {tag}
                    <IconButton
                      onClick={() => handleRemoveTag(tag)} // Remove tag functionality
                      size="small"
                      sx={{
                        marginLeft: "5px",
                        padding: "2px",
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>

            <NotificationToggle
              defaultTitle="Novo Serviço Adicionado"
              defaultBody={`${values.name} já faz parte da sua lista de serviços!`}
              screen="/services"
              onSend={(data) => setNotificationData(data)}
            />


            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem", // Add margin-bottom of 1rem
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? colors.customTheme.primary
                      : colors.customTheme.primary,
                  fontWeight: 600,
                  color: "#fff", // Text color based on theme
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                  },
                }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar documento..." : "Criar Serviço"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>



      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  schedule: yup.string().required("Campo obrigatório"),
  address: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  name: "",
  type: "",
  schedule: "",
  address: "",
  photo: null,
};

export default Form;
