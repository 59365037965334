import React, { useState } from "react";
import { Box, Button, TextField, Alert, Snackbar } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";


import NotificationToggle from "../../global/ShowNotificationField";

const Form = ({ onClose, refreshContacts, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);





  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [notificationData, setNotificationData] = useState(null);



  const handleFormSubmit = async (values, setSubmitting) => {
    const requestBody = {
      fullName: values.fullName,
      phone: values.phone,
      email: values.email,
      facebook: values.facebook,
      instagram: values.instagram,
      websiteURL: values.websiteURL,
    };

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/contacts`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
        },
        body: JSON.stringify(requestBody),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.text();
      // console.log("Success:", responseData);

      refreshContacts();
      showSnackbar("Contacto adicionado com sucesso!");

      if (notificationData && notificationData.title && notificationData.body) {
        await sendNotification(notificationData);
      }


      onClose();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao adicionar contacto. Tente outra vez");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);     
    }

    setSubmitting(false);
  };


  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/notifications/send`,
        {
          method: "POST",
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
          credentials: "include",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbarMessage(`Error sending notification: ${errorData.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending notification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box >
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
              

              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
               
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Número de telemóvel"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
               
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Facebook URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.facebook}
                name="facebook"
                error={!!touched.facebook && !!errors.facebook}
                helperText={touched.facebook && errors.facebook}
              /**
               *   InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
               */
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Instagram URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.instagram}
                name="instagram"
                error={!!touched.instagram && !!errors.instagram}
                helperText={touched.instagram && errors.instagram}
              
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Website URL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.websiteURL}
                name="websiteURL"
                error={!!touched.websiteURL && !!errors.websiteURL}
                helperText={touched.websiteURL && errors.websiteURL}
               
              />
            </Box>

            <NotificationToggle
              defaultTitle="Nova Contacto Adicionado"
              defaultBody={`Expande a tua rede de contactos`}
              screen="/contacts"
              onSend={(data) => setNotificationData(data)}
            />




            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem", // Add margin-bottom of 1rem
                  backgroundColor: colors.customTheme.primary , 
                  color: colors.customTheme.textColor, 
                  fontWeight:600,

                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary, 
                  },
                }}                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar contacto..." : "Criar Contacto"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("Campo obrigatório"),
  email: yup.string().email("Email inválido").required("Campo obrigatório"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Número de telefone inválido")
    .required("Campo obrigatório"),
});

const initialValues = {
  fullName: "",
  email: "",
  phone: "",
  facebook: "",
  instagram: "",
  websiteURL: "",
};

export default Form;
