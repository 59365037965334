import { useRef, useState } from "react";
import PersonIcon from "@mui/icons-material/PersonOutline";
import { Link } from "react-router-dom";
import "./index.css";

function ForgotPassword() {
  const emailInputRef = useRef();
  const [statusMessage, setStatusMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;

    setIsSubmitting(true); // Disable the button while submitting

    try {
      // Send the request to the backend
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/backoffice/requestResetPassword?email=${encodeURIComponent(
          enteredEmail
        )}`,
        {
          method: "GET", // You might need to adjust this to POST based on the backend setup
          headers: {
            "X-University-Token": `${process.env.REACT_APP_API_UNIVERSITY_TOKEN}`
          },
        }
      );

      const responseData = await response.text();

      if (response.ok) {
        setStatusMessage(
          "Um email foi enviado para redefinir a sua palavra-passe. Por favor, verifique a sua caixa de entrada."
        );
      } else {
        setStatusMessage(
          responseData ||
            "Erro ao tentar redefinir a palavra-passe. Por favor, tente novamente."
        );
      }
    } catch (error) {
      setStatusMessage(
        "Ocorreu um erro ao processar o pedido. Por favor, tente mais tarde."
      );
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  return (
    <div className={"login-page"}>
      <div>
        <title className={"loginTitle"}>Esqueceu-se da palavra passe?</title>
        <div className="subtitle">
          Indique o email da sua conta para poder redefinir a sua palavra-passe
        </div>
      </div>

      <form onSubmit={submitHandler} className={"form"}>
        <div>
          <PersonIcon className={"icon"} />

          <input
            className={"input"}
            type="email"
            id="user-name"
            name="user-name"
            autoComplete="on"
            placeholder="Nome de utilizador ou E-mail"
            ref={emailInputRef}
            required
          ></input>
        </div>

        <div className="forgot-password">
          <Link to="/login" className="forgot-password-link">
            Iniciar Sessão
          </Link>
        </div>

        <button className={"loginBtn"} type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Enviando..." : "Redefinir Palavra-Passe"}
        </button>
      </form>
      {statusMessage && <p className="status-message">{statusMessage}</p>}
    </div>
  );
}

export default ForgotPassword;
